
    import React, { useEffect, useRef } from 'react';
    import { FaTimes } from 'react-icons/fa';
    import Barcode from 'react-barcode';
    
    const TableWithPopup = ({ onClose, selectedNote }) => {
      const popupRef = useRef(null);
    
      const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
          onClose(); // Close the popup
        }
      };
    
      useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);
    
      return (
        <div className="fixed inset-0 flex items-center justify-center w-full h-full bg-opacity-50 glassmorphism z-50">
          <div
            ref={popupRef}
            className="bg-white text-black p-6 rounded-lg shadow-lg w-full md:w-2/3 2xl:w-1/3 mx-10 relative flex flex-col items-center justify-center"
          >
            {selectedNote}
            <button onClick={onClose} className="absolute right-4 top-4 text-red-500">
              <FaTimes />
            </button>
          </div>
        </div>
      );
    };
    
    export default TableWithPopup;
    