/* eslint-disable */
import React from "react";
import { Link, useLocation } from "react-router-dom";
import DashIcon from "components/icons/DashIcon";

export function SidebarLinks(props) {
  let location = useLocation();
  const { routes } = props;

  // Verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  const checkLogOut = (event) => {
      // Prevent the default behavior of the Link
      event.preventDefault();

      // Delete the specific session storage items
      sessionStorage.removeItem('email');
      sessionStorage.removeItem('sessionId');
      sessionStorage.removeItem('userName');
      sessionStorage.removeItem('phone');

      // Redirect the user to the /auth/sign-in route
      window.location.href = "/auth/sign-in";
  };

  const createLinks = (routes) => {
    return routes.map((route, index) => {

      if (route.layout === "/admin") {
        return (
          <Link key={index} to={route.layout + "/" + route.path}>
            <div className="relative mb-3 flex hover:cursor-pointer py-2">
              <li
                className="my-[3px] flex cursor-pointer items-center px-8"
                key={index}
              >
                <span
                  className={`${
                    activeRoute(route.path) === true
                      ? "font-bold text-blue-500 dark:text-white"
                      : "font-medium text-gray-100"
                  }`}
                >
                  {route.icon ? route.icon : <DashIcon />}{" "}
                </span>
                <p
                  className={`leading-1 ml-4 flex ${
                    activeRoute(route.path) === true
                      ? "font-bold text-blue-500 dark:text-white"
                      : "font-medium text-gray-100"
                  }`}
                >
                  {route.name}
                </p>
              </li>
              {activeRoute(route.path) ? (
                <div class="absolute right-0 top-px h-full w-1 rounded-lg bg-blue-500" />
              ) : null}
            </div>
          </Link>
        );
      }
      if (route.layout === "/auth") {
        return (
          <button key={index} onClick={checkLogOut}>
            <div className="relative mb-3 flex hover:cursor-pointer py-2">
              <li
                className="my-[3px] flex cursor-pointer items-center px-8"
                key={index}
              >
                <span
                  className={`font-bold text-red-500 dark:text-white`}
                >
                  {route.icon ? route.icon : <DashIcon />}{" "}
                </span>
                <p
                  className={`leading-1 ml-4 flex font-bold text-red-500 dark:text-white`}
                >
                  {route.name}
                </p>
              </li>
            </div>
          </button>
        );
      }
    });
  };

  return createLinks(routes);
}

export default SidebarLinks;
