import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { FaTimes, FaInfoCircle, FaSyncAlt } from 'react-icons/fa';
import OrderDetailsPopup from './DetailPopup';
import BarcodePopup from './BarcodePopup'; 
import { ImBarcode } from "react-icons/im";
import LoadingPage from 'components/loading/LoadingPage';


const PharmacyTable = () => {
  const [orders, setOrders] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [filters, setFilters] = useState([]);
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false); 
  const [selectedCF, setSelectedCF] = useState(null);
  const [isLoading, setIsLoading] = useState(true); 


  const fetchTickets = useCallback(async () => {
    const header = sessionStorage.getItem('sessionId');
    try {
      const response = await axios.get('https://app.oxyera.com/pharmacy/facility/orders', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': header,
        },
        withCredentials: false,
      });
      if (response.status === 200) {
        setOrders(response.data);
        setIsLoading(false);
        return;
      }
      if (response.status === 401) {
        console.log('No token provided');
        setIsLoading(false);
        return;
      }
    } catch (error) {
      console.log('Error during API request:', error);
      setIsLoading(false);
    }
  }, []);

  const handleRefreshClick = () => {
    setIsRefreshing(true); // Start the rotation

    // Fetch the updated data
    fetchTickets().finally(() => {
      // Stop the rotation after 2 seconds
      setTimeout(() => {
        setIsRefreshing(false);
      }, 2000);
    });
  };



  useEffect(() => {
    // Call fetchTickets immediately when the component mounts
    fetchTickets();

    // Set up an interval to call fetchTickets every minute (60000 ms)
    const intervalId = setInterval(fetchTickets, 60000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array to ensure it only runs once on mount


  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const toggleFilterDropdown = () => {
    setShowFilterDropdown(!showFilterDropdown);
  };

  const handleFilterChange = (newFilter) => {
    if (!filters.includes(newFilter)) {
      setFilters([...filters, newFilter]);
    }
    setShowFilterDropdown(false);
  };

  const removeFilter = (filterToRemove) => {
    setFilters(filters.filter(filter => filter !== filterToRemove));
  };

  const sortedOrders = orders.sort((a, b) => new Date(b.datetime) - new Date(a.datetime));

  const filteredOrders = sortedOrders.filter((order) => {
    const matchesSearchQuery = order.id; 
    // ||
    //                            order.patient_fiscal_code.toLowerCase().includes(searchQuery.toLowerCase());
    
    const matchesFilters = filters.length === 0 || filters.some(filter => {
      switch (filter) {
        case 'richieste':
          return order.status_id === 1;
        case 'confermati':
          return order.status_id === 2;
        case 'completati':
          return order.status_id === 4;
        case 'annullati':
          return order.status_id === 5;
        case 'spedito':
          return order.status_id === 3;
        default:
          return true;
      }
    });
    return matchesSearchQuery && matchesFilters;
  });

  const handleInfoClick = (order) => {
    setSelectedOrder(order); // Set the selected order to state
  };

  const closePopup = () => {
    setSelectedOrder(null); 
    fetchTickets();
  };

  useEffect(() => {
    if (selectedOrder) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, [selectedOrder]);

  const openBarcodePopup = (cf) => {
    setSelectedCF(cf); // Set the selected CF code
  };

  const closeBarcodePopup = () => {
    setSelectedCF(null); // Reset the selected CF code
  };

  // Render the LoadingPage while fetching data
  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <div className="bg-white text-blue-oxyera lg:px-10 rounded-xl relative">
      <div className="flex justify-between items-center mb-10">
        <h2 className="text-3xl text-center font-semibold text-black-500">I tuoi Ordini B2B</h2>
        <button 
          onClick={handleRefreshClick} 
          className="text-blue-500 font-semibold flex items-center space-x-2 hover:text-blue-700"
          title="Refresh Orders"
        >
          <FaSyncAlt 
            className={`mr-2 transition-transform ${isRefreshing ? 'animate-spin' : ''}`} 
          /> 
          Aggiorna
        </button>
      </div>

      <div className="flex flex-col md:flex-row justify-between md:justify-center items-center mb-4">
        <div className="relative w-full">
          <div className='flex flex-row items-center'>
          <input
            type="text"
            placeholder="Cerca per Numero ordine . . ."
            value={searchQuery}
            onChange={handleSearchChange}
            className="px-4 py-2 rounded w-full border border-black-500 text-black-500 bg-custom-lightgrey"
          />
          <button
            onClick={toggleFilterDropdown}
            className="text-blue-500 ms-5 font-semibold"
          >
            Filtro
          </button>
          {showFilterDropdown && (
            <div className="absolute right-0 top-full mt-2 bg-white border border-gray-300 rounded shadow-lg z-10">
              <button onClick={() => handleFilterChange("richieste")} className="block px-4 py-2 text-blue-oxyera hover:bg-blue-500 hover:text-white w-full text-start">Richieste</button>
              <button onClick={() => handleFilterChange("confermati")} className="block px-4 py-2 text-blue-oxyera hover:bg-blue-500 hover:text-white w-full text-start">Confermati</button>
              <button onClick={() => handleFilterChange("completati")} className="block px-4 py-2 text-blue-oxyera hover:bg-blue-500 hover:text-white w-full text-start">Completati</button>
              <button onClick={() => handleFilterChange("annullati")} className="block px-4 py-2 text-blue-oxyera hover:bg-blue-500 hover:text-white w-full text-start">Annullati</button>
            </div>
          )}
          </div>
        </div>
      </div>

      <div className="flex flex-wrap gap-2 mb-4">
        {filters.map(filter => (
          <div key={filter} className="flex items-center bg-blue-100 text-blue-600 px-3 py-1 rounded-full">
            {filter.charAt(0).toUpperCase() + filter.slice(1)}
            <button onClick={() => removeFilter(filter)} className="ml-2 text-blue-600">
              <FaTimes />
            </button>
          </div>
        ))}
      </div>

      {filteredOrders.length === 0 ? (
        <p>Non ci sono ordini al momento!</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full text-black">
            <thead>
              <tr className="bg-black-50 text-black-500">
                <th className="py-2 px-4 border border-black-500 text-center cursor-pointer" onClick={() => handleSort('orderType')}>ID ordine</th>
                <th className="py-2 px-4 border border-black-500 text-center cursor-pointer" onClick={() => handleSort('totalOrder')}>Tipo Ordine</th>
                <th className="table-cell py-2 px-4 border border-black-500 text-center cursor-pointer" onClick={() => handleSort('products')}>Data consegna</th>
                <th className="table-cell py-2 px-4 border border-black-500 text-center cursor-pointer" onClick={() => handleSort('date')}>Stato</th>
                {/* <th className="table-cell py-2 px-4 border border-black-500 text-center cursor-pointer" onClick={() => handleSort('state')}>Paziente</th> */}
                <th className="table-cell py-2 px-4 border border-black-500 text-center cursor-pointer" onClick={() => handleSort('prodotti')}>Prodotti</th>
                <th className="table-cell py-2 px-4 border border-black-500 text-center cursor-pointer" onClick={() => handleSort('details')}>Dettagli</th>
              </tr>
            </thead>
            <tbody>
              {filteredOrders.map((order, index) => (
                <tr key={index} className="">
                  <td className="py-2 px-4 border border-black-500 text-center text-black-500">{order.id}</td>
                  <td className="table-cell py-2 px-4 border border-black-500 text-center text-black-500">
                    Consegna
                  </td>
                  <td className="py-2 px-4 border border-black-500 text-center text-black-500">
                    {new Date(order.datetime).toLocaleDateString('it-IT', { timeZone: 'UTC' })}
                  </td>
                  <td className={`py-2 px-4 border border-black-500 text-center text-black-500 
                    ${order.status_id === 5 && 'text-red-500'}
                    ${order.status_id === 1 && 'text-yellow-500'}
                    ${order.status_id === 4 && 'text-green-500'}
                    ${order.status_id === 3 && 'text-blue-500'}
                    ${order.status_id === 2 && 'text-blue-500'}
                  `}>
                  {order.status_id === 1 && 'Richiesto'}
                  {order.status_id === 3 && 'Spedito'}
                  {order.status_id === 2 && 'Confermato'}
                  {order.status_id === 5 && 'Cancellato'}
                  {order.status_id === 4 && 'Completato'}
                  </td>
                  {/* <td className="py-2 px-4 border border-black-500 text-center text-black-500">
                    <button onClick={() => openBarcodePopup(order.patient_fiscal_code)} className="ml-2 text-blue-500 flex flex-row items-center gap-4">
                        {order.patient_fiscal_code || 'N/A'} 
                        {order.patient_fiscal_code && (
                                <ImBarcode className='ms-1 text-xl'/>
                        )}
                    </button>
                  </td> */}
                  <td className="py-2 px-4 border border-black-500 text-center text-black-500">
                    {order.total_quantity}
                  </td>
                  {/* <td className="py-2 px-4 border border-black-500 text-center text-black-500">
                    {order.total_quantity}
                  </td> */}
                  <td className="py-2 px-4 border border-black-500 text-center text-black-500">
                    <div className='flex justify-center'>
                      <button onClick={() => handleInfoClick(order)} className='text-white text-xs bg-blue-500 px-4 py-1 rounded-full flex items-center space-x-2 hover:scale-[102%] me-5'>
                        Dettagli
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {selectedCF && (
        <BarcodePopup cfCode={selectedCF} onClose={closeBarcodePopup} />
      )}

      {selectedOrder && (
        <OrderDetailsPopup order={selectedOrder} onClose={closePopup} />
      )}
    </div>
  );
};

export default PharmacyTable;
