import axios from 'axios';
import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';

const RecoveryModal = ({ onClose }) => {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [accessToken, setAccessToken] = useState('');

  const handleNextStep = async () => {
    if (step === 1) {
      if (!email) {
        setErrorMessage('Per favore, inserisci il tuo indirizzo email.');
        return;
      }
    try {
        const response = await axios.post(`https://app.oxyera.com/auth/pharmacy/forgot_password`, 
            { 
                "email": email
            }, 
        );
        if (response.status === 200) {
            setAccessToken("Bearer " + response.data);
        }
        if (response.status === 404) {
            setErrorMessage('Utente non trovato');
            return;
        }
    } catch (error) {
        console.log('Error during API request:', error);
        return;
    }

    } else if (step === 2) {
      if (!code) {
        setErrorMessage('Per favore, inserisci il codice di conferma.');
        return;
      }
      try {
            const response = await axios.post(`https://app.oxyera.com/auth/pharmacy/forgot_password`, 
                { 
                    "confirmation_code": code
                }, 
                {
                    headers: {
                    'Content-Type': 'application/json',
                    'Authorization': accessToken,
                    },
                    withCredentials: false,
                }
            );
            if (response.status === 200) {
                console.log(response.data);
            }
            if (response.status === 401) {
                setErrorMessage('Il codice inserito non è corretto.');
                return;
            }
            if(response.status === 404){
                setErrorMessage('Utente non trovato.');
                return;
            }
        } catch (error) {
            console.log('Error during API request:', error);
            return;
        }
    } else if (step === 3) {
      if (!newPassword || !confirmPassword) {
        setErrorMessage('Per favore, inserisci e conferma la nuova password.');
        return;
      }
      if (newPassword !== confirmPassword) {
        setErrorMessage('Le password non corrispondono. Per favore, riprova.');
        return;
      }

        try {
            const response = await axios.post(`https://app.oxyera.com/auth/pharmacy/reset_password`, 
                { 
                    "new_password": confirmPassword
                }, 
                {
                    headers: {
                    'Content-Type': 'application/json',
                    'Authorization': accessToken,
                    },
                    withCredentials: false,
                }
            );
            if (response.status === 200) {
                console.log(response.data);
            }
            if (response.status === 401) {
                setErrorMessage('Non hai le autorizzazioni necessarie per proseguire.');
                return;
            }
            if(response.status === 404){
                setErrorMessage('Utente non trovato.');
                return;
            }
        } catch (error) {
            console.log('Error during API request:', error);
            return;
        }
      
    }
    setErrorMessage('');
    setStep(step + 1);
  };

  const handlePreviousStep = () => {
    setErrorMessage('');
    setStep(step - 1);
  };

  const handleSubmit = () => {
    if (!newPassword || !confirmPassword) {
      setErrorMessage('Per favore, inserisci e conferma la nuova password.');
      return;
    }
    if (newPassword !== confirmPassword) {
      setErrorMessage('Le password non corrispondono. Per favore, riprova.');
      return;
    }
    setStep(4); // Move to the success message step
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center w-full h-full bg-opacity-50 bg-gray-700 z-50 glassmorphism">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full md:w-1/3 mx-10 relative">
        <h3 className="text-xl font-semibold mb-4 text-center">Recupera Password</h3>

        {step === 1 && (
          <div>
            <label className="block mb-2">Indirizzo Email:</label>
            <input
              type="email"
              className="w-full p-2 border rounded mb-4"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Inserisci qui il tuo indirizzo email"
            />
            {errorMessage && (
              <div className="mt-2 text-red-500 text-sm">
                {errorMessage}
              </div>
            )}
          </div>
        )}
        
        {step === 2 && (
          <div>
            <label className="block mb-2">Codice di Conferma:</label>
            <input
              type="text"
              className="w-full p-2 border rounded mb-4"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              placeholder="Inserisci il codice"
            />
            <p className="text-sm text-red-500">* Il codice è stato inviato al tuo indirizzo email.</p>
            {errorMessage && (
              <div className="mt-2 text-red-500 text-sm">
                {errorMessage}
              </div>
            )}
          </div>
        )}
        
        {step === 3 && (
          <div>
            <label className="block mb-2">Nuova Password:</label>
            <input
              type="password"
              className="w-full p-2 border rounded mb-4"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="Inserisci la nuova password"
            />
            <label className="block mb-2">Conferma Nuova Password:</label>
            <input
              type="password"
              className="w-full p-2 border rounded mb-4"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Conferma la nuova password"
            />
            {errorMessage && (
              <div className="mt-2 text-red-500 text-sm">
                {errorMessage}
              </div>
            )}
          </div>
        )}
        
        {step === 4 && (
          <div className="text-center">
            <p className="text-green-600 text-lg font-semibold mb-4">
              La password è stata cambiata con successo!
            </p>
            <button onClick={onClose} className="px-10 py-2 text-sm bg-blue-500 text-white rounded">
              Chiudi
            </button>
          </div>
        )}

        {step < 4 && (
          <div className="flex justify-center gap-5 mt-4">
            {step > 1 && (
              <button onClick={handlePreviousStep} className="px-10 py-2 bg-red-500 text-white text-sm bg-gray-300 rounded">
                Indietro
              </button>
            )}
            {step < 3 && (
              <button onClick={handleNextStep} className="px-10 py-2 text-sm bg-blue-500 text-white rounded">
                Avanti
              </button>
            )}
            {step === 3 && (
              <button onClick={handleSubmit} className="px-10 py-2 text-sm bg-green-600 text-white rounded">
                Conferma nuova password
              </button>
            )}
          </div>
        )}
        
        <button onClick={onClose} className="absolute right-4 top-4 text-red-500">
          <FaTimes />
        </button>
      </div>
    </div>
  );
};

export default RecoveryModal;
