import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { FaTimes } from 'react-icons/fa';
import BarcodePopup from './BarcodePopup'; 
import { ImBarcode } from "react-icons/im";
import LoadingPage from 'components/loading/LoadingPage';
import { MdEventNote } from "react-icons/md";
import TableWithPopup from './TableWithPopup';


const OrderDetailsPopup = ({ order, onClose }) => {
  const [orderStatus, setOrderStatus] = useState(order ? order.status_id : '');
  const [orderDetail, setOrderDetail] = useState([]);
  const [loading, setLoading] = useState(true); // New state for loading
  const header = sessionStorage.getItem('sessionId');
  const popupDetailRef = useRef(null);
  const [selectedCF, setSelectedCF] = useState(null);
  const [selectedAIC, setSelectedAIC] = useState(null);
  const [showNotePopup, setShowNotePopup] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);

  const fetchTickets = useCallback(async () => {
    setLoading(true); // Set loading to true when the fetch starts
    try {
      const response = await axios.get(`https://app.oxyera.com/pharmacy/facility/order/${order.id}`, 
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': header,
          },
          withCredentials: false,
        }
      );
      if (response.status === 200) {
        setOrderDetail(response.data);
      } else if (response.status === 401) {
        console.log('No token provided');
      }
    } catch (error) {
      console.log('Error during API request:', error);
    } finally {
      setLoading(false); // Set loading to false when the fetch completes
    }
  }, [order.id]);

  useEffect(() => {
    fetchTickets();
  }, [fetchTickets]);

  const handleClickOutside = (event) => {
    if (popupDetailRef.current && !popupDetailRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const openBarcodePopupAIC = (aic) => {
    setSelectedAIC(aic); 
  };

  const openBarcodePopupCF = (cf) => {
    setSelectedCF(cf); 
  };

  const closeBarcodePopup = () => {
    setSelectedAIC(null); 
    setSelectedCF(null); 
  };

  const handleConfirmClick = async () => {
    try {
      const response = await axios.patch(`https://app.oxyera.com/pharmacy/facility/confirm_order`, 
        { order_id: order.id },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': header,
          },
          withCredentials: false,
        }
      );
      if (response.status === 200) {
        setOrderStatus(2); // Update order status to 'Confermato'
        fetchTickets(); // Refresh order details
      } else if (response.status === 400) {
        console.log('No token provided');
      } else if (response.status === 401) {
        console.log('Invalid access token');
      } else if (response.status === 404) {
        console.log('Order not found');
      }
    } catch (error) {
      console.log('Error during API request:', error);
    }
  };
  
  const handleCompleteClick = async () => {
      try {
        const response = await axios.patch(`https://app.oxyera.com/pharmacy/facility/complete_order`, 
          { order_id: order.id },
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': header,
            },
            withCredentials: false,
          }
        );
        if (response.status === 200) {
          setOrderStatus(4);
          fetchTickets(); 
        } else if (response.status === 400) {
          console.log('No token provided');
        } else if (response.status === 401) {
          console.log('Invalid access token');
        } else if (response.status === 404) {
          console.log('Order not found');
        }
      } catch (error) {
        console.log('Error during API request:', error);
      }

  };

  const closeNotePopup = () => {
    setShowNotePopup(false);
  }

  const openNotePopup = (a) => {
    setShowNotePopup(true);
    setSelectedNote(a);
  }
  

  const handleCancelClick = async () => {
    try {
      const response = await axios.patch(`https://app.oxyera.com/pharmacy/facility/cancel_order`, 
        { order_id: order.id },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': header,
          },
          withCredentials: false,
        }
      );
      if (response.status === 200) {
        console.log(response.data);
        setOrderStatus(5); // Update order status to 'Cancellato'
        fetchTickets(); // Refresh order details
      } else if (response.status === 400) {
        console.log('No token provided');
      } else if (response.status === 401) {
        console.log('Invalid access token');
      } else if (response.status === 404) {
        console.log('Order not found');
      }
    } catch (error) {
      console.log('Error during API request:', error);
    }
  };
  
  if (!order) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center w-full h-full bg-opacity-50 glassmorphism z-50 overflow-y-auto">
      <div ref={popupDetailRef} className="bg-white text-black-500 p-6 rounded-lg shadow-lg w-full md:w-3/4 xl:w-2/3 mx-10 relative">
      {loading ? (
          <LoadingPage /> // Loading indicator
        ) : (
          <>
            <div className='flex justify-between items-center mb-4'>
              <h3 className="text-xl font-semibold">Dettagli Ordine #{order.id}</h3>

              <div className="flex flex-row items-end me-10 text-blak-500">
                <div className="">
                  
                  {orderStatus === 1 && 
                  <div className="flex flex-row items-end text-black-500">
                    <button 
                      onClick={handleConfirmClick} 
                      className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
                    >
                      Conferma
                    </button>
                    <button 
                      onClick={handleCancelClick} 
                      className="bg-red-500 text-white px-4 py-2 rounded"
                    >
                      Annulla
                    </button>
                  </div>
                  }

                {orderStatus === 2 && 
                  <div className="flex flex-row items-end text-black-500">
                    <button 
                      onClick={handleCompleteClick} 
                      className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
                    >
                      Completa
                    </button>
                  </div>
                }

                </div>
              </div>
              <button onClick={onClose} className="absolute right-4 top-4 text-red-500">
                <FaTimes />
              </button>
            </div>
            <div className="grid grid-cols-1">
              <div className='overflow-x-auto custom-scrollbar'>
                <p><strong>Tipo Ordine:</strong> Consegna</p>
                <p><strong>Quantità:</strong> {order.total_quantity}</p>
                <p><strong>Data Consegna:</strong> {new Date(order.datetime).toLocaleDateString('it-IT', { timeZone: 'UTC' })}</p>
                {/* <p><strong>Indirizzo:</strong> {order.pharmacy.address}</p> */}
                <p><strong>Stato Ordine:</strong> 
                    {orderStatus === 1 && <span className="text-yellow-500"> Richiesto</span>}
                    {orderStatus === 2 && <span className="text-blue-500"> Confermato</span>}
                    {orderStatus === 4 && <span className="text-green-500"> Completato</span>}
                    {orderStatus === 5 && <span className="text-red-500"> Cancellato</span>}
                    {orderStatus === 3 && <span className="text-blue-500"> Spedito</span>}
                </p>


                <div className='overflow-y-auto h-[60vh] pe-5 custom-scrollbar'>
                  <table className="min-w-full text-black mt-6 mb-4">
                    <thead>
                      <tr className="bg-black-50 text-black-500">
                      <th className="py-2 px-4 border border-black-500 text-center cursor-pointer text-start">Nome Paziente</th>
                      <th className="py-2 px-4 border border-black-500 text-center cursor-pointer text-start">Codice Fiscale</th>
                        <th className="py-2 px-4 border border-black-500 text-center cursor-pointer text-start">Nome Farmaco</th>
                        <th className="py-2 px-4 border border-black-500 text-center cursor-pointer text-start">Quantità</th>
                        <th className="table-cell py-2 px-4 border border-black-500 text-center cursor-pointer text-start">AIC</th>
                        <th className="table-cell py-2 px-4 border border-black-500 text-center cursor-pointer text-start">Azienda</th>
                        <th className="py-2 px-4 border border-black-500 text-center cursor-pointer text-start">Nota</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderDetail.map((detail, index) => (
                        <tr key={index} className="">
                          <td className="py-2 px-4 border border-black-500 text-start">
                            {detail.facility_patient.firstname} {detail.facility_patient.lastname}
                          </td>
                          <td className="py-2 px-4 border border-black-500 text-start">
                            <div className='flex justify-start items-center'>
                              <button onClick={() => openBarcodePopupAIC(detail.facility_patient.fiscal_code)} className="ml-2 text-blue-500 flex flex-row items-center gap-4">
                                {detail.facility_patient.fiscal_code || 'N/A'} 
                                {detail.facility_patient.fiscal_code && (
                                  <ImBarcode className='ms-1 text-xl'/>
                                )}
                              </button>
                            </div>
                          </td>                        
                          <td className="py-2 px-4 border border-black-500 text-start">
                            {detail.medicine_drug ? detail.medicine_drug.split('*')[0].trim() : 'N/A'}
                            {detail.medicine_drug ? detail.medicine_drug.split('*')[1]?.trim() : 'N/A'}
                          </td>
                          <td className="py-2 px-4 border border-black-500 text-start">
                            {detail.medicine_quantity}
                          </td>                        
                          <td className="py-2 px-4 border border-black-500 text-start">
                            <div className='flex justify-start items-center'>
                              <button onClick={() => openBarcodePopupAIC(detail.medicine_aic)} className="ml-2 text-blue-500 flex flex-row items-center gap-4">
                                {detail.medicine_aic || 'N/A'} 
                                {detail.medicine_aic && (
                                  <ImBarcode className='ms-1 text-xl'/>
                                )}
                              </button>
                            </div>
                          </td>
                          <td className="py-2 px-4 border border-black-500 text-start">
                            {detail.medicine_company || 'N/A'}
                          </td>
                          <td className="border border-black-500 text-center">
                            <div className='flex justify-center items-center w-full'>
                              {detail.note !== '' && <button onClick={() => openNotePopup(detail.note)}><MdEventNote/></button>}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        )}

        {selectedAIC && (
          <BarcodePopup aicCode={selectedAIC} cfCode={null} onClose={closeBarcodePopup} />
        )}
        {selectedCF && (
          <BarcodePopup cfCode={selectedCF} aicCode={null} onClose={closeBarcodePopup} />
        )}
        {showNotePopup && <TableWithPopup selectedNote={selectedNote} onClose={closeNotePopup}/> }
      </div>
    </div>
  );
};

export default OrderDetailsPopup;
