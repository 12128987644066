/* eslint-disable */
import React, { useEffect, useRef } from 'react';
import Links from "./components/Links";
import Logo from "../../assets/logo/OXYERA_Logo_nbg.png";
import routes from "routes.js";

const Sidebar = ({ open, onClose }) => {
  const sidebarRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (window.innerWidth < 1200) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <div ref={sidebarRef}
      className={`sm:none duration-175 linear fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-grey-700 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
    >

      <div className={`flex items-center justify-center`}>
        <div className="flex justify-center items-center w-56 me-0 sme-2 py-">
          <img src={Logo} alt="Logo" className="h-16 w-auto my-5" />
          <p className="ml-3 text-3xl font-semibold text-blue-oxyera">Oxyera</p>
        </div>
      </div>
      {/* Nav item */}

      <ul className="mb-auto">
        <Links routes={routes} />
      </ul>

      {/* Nav item end */}
    </div>
  );
};

export default Sidebar;
