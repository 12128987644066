import React, { useContext, useEffect, useRef, useState } from "react";
import { FiAlignJustify, FiSearch } from "react-icons/fi";
import { Link } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import { GlobalStateContext } from '../../context/GlobalStateContext';
import farmacie from '../../assets/logo/farmacie.png';


const Navbar = (props) => {
  const { onOpenSidenav, brandText, sideBarStatus } = props;
  const searchRef = useRef(null);
  const { userData } = useContext(GlobalStateContext);
  const userName = sessionStorage.getItem('userName');

  return (
    <>
    <nav className="sticky rounded-2xl top-4 z-40 flex flex-row  items-center justify-between bg-white/50 p-2 backdrop-blur-xl dark:bg-secondary-200">
      <div className="ml-[6px]">
        <div className="h-6 w-[224px] pt-1">
          <a
            className="text-sm font-normal text-navy-700 hover:underline dark:text-white dark:hover:text-white"
            href=" "
          >
            <span className="mx-1 text-sm text-navy-700 hover:text-navy-700 dark:text-white">
              {" "}
              /{" "}
            </span>
          </a>
          <Link
            className="text-sm font-normal capitalize text-navy-700 hover:underline dark:text-white dark:hover:text-white"
            to="#"
          >
            {brandText}
          </Link>
        </div>
        <p className="shrink text-xl m-2 capitalize text-navy-700 dark:text-white">
          <Link
            to="#"
            className="font-bold capitalize hover:text-navy-700 dark:hover:text-white"
          >
            {userName}
          </Link>
        </p>
      </div>

      <div
        className="relative mt-[3px] flex h-[61px] w-[355px] flex-grow items-center justify-end gap-2 rounded-full  px-2 py-2  dark:!bg-grey-200 md:w-[365px] md:flex-grow-0 md:gap-1 xl:w-[365px] xl:gap-2"
        ref={searchRef}
      >
        {!sideBarStatus ? 
          <span
            className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden"
            onClick={() => onOpenSidenav(true)}
          >
            <FiAlignJustify className="h-5 w-5" />
          </span>
          :
          <span
          className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden"
          >
            <IoClose className="h-5 w-5" />
          </span>
        }
        <Link to="/admin/account">
          <img
            className="h-10 w-10 rounded-full xl:block hidden bg-white"
            src={farmacie}
            alt="User Profile"
          />
        </Link>
      </div>
    </nav>
    </>
  );
};

export default Navbar;
