import React from "react";

// Admin Imports
import Home from "views/admin/home";
import 
Rsa from "views/admin/rsa";
import Support from "views/admin/support/index";
import UserProfile from "views/admin/userProfile/index";
import { IoIosPaper } from "react-icons/io";


// Auth Imports
import SignIn from "views/auth/SignIn";
import { FaRegUser } from "react-icons/fa";
import { BiSupport } from "react-icons/bi";
import { MdOutlineDashboard } from "react-icons/md";



// Icon Imports
import {
  MdOutlineLogout, 
} from "react-icons/md";

const routes = [
  // {
  //   name: "Dashboard",
  //   layout: "/admin",
  //   path: "dashboard",
  //   icon: <MdOutlineDashboard  className="h-6 w-6" />,
  //   component: <Home />,
  // },
  {
    name: "Ordini RSA",
    layout: "/admin",
    path: "ordini-RSA",
    icon: <IoIosPaper  className="h-6 w-6" />,
    component: <Rsa />,
  },
  {
    name: "Assistenza",
    layout: "/admin",
    path: "assistenza",
    icon: <BiSupport className="h-6 w-6" />,
    component: <Support />,
  },
  {
    name: "Il mio profilo",
    layout: "/admin",
    path: "account",
    icon: <FaRegUser  className="h-6 w-6" />,
    component: <UserProfile />,
  },
  {
    name: "Esci",
    layout: "/auth",
    path: "sign-in",
    icon: <MdOutlineLogout className="h-6 w-6" />,
    component: <SignIn />,
  }
];
export default routes;
