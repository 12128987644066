import React, { createContext, useState } from 'react';

// Create a context
export const GlobalStateContext = createContext();

// Create a provider component
export const GlobalStateProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);

  return (
    <GlobalStateContext.Provider value={{ userData, setUserData }}>
      {children}
    </GlobalStateContext.Provider>
  );
};
